.car-image-kszk {
  position: relative;
}

.car-text-kszk {
  position: absolute;
  width: 100%;
  top: 1vw;
  text-shadow: black 0px 0px 10px, black 0px 0px 6px;
  -webkit-text-fill-color: white;
}

.quote {
  position: relative;
  margin-bottom: 0.5rem;
}

.quote:before {
  content: '“';
  position: absolute;
  left: -0.45em;
}

.quote::after {
  content: '”';
  margin-right: -1rem;
}

.quote--container {
  margin: 2rem auto 0;
  padding-bottom: 0.7rem;
}

.quote--author {
  text-align: right;
  font-weight: 300;
}
