#task,
#tasknote {
  border: none;
  background-color: inherit;
  color: teal;
}

#tasknote {
  color: black;
}

#task:hover,
#tasknote:hover {
  color: red;
  cursor: pointer;
}
