.news-extra {
  color: grey;
  font-size: 0.75em;
  font-style: italic;
  font-family: fantasy;
  text-align: justify;
}

.news-text {
  font-size: 1.15em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
}
